<template>
  <main><HomePage/></main>
</template>

<script>
import HomePage from "./components/HomePage.vue";

export default {
  name: "App",
  components: {
    HomePage,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans&display=swap");

#app {
  font-family: "Josefin Sans", Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #dcdcdc;
  background-color: #1a1a1a;
  min-height: 100vh;
}
body {
  margin: 0;
  padding: 0;
  font-size: 1rem;
}

html {
  font-size: 16px;
}
</style>