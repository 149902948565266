<template>
    <div
      class="iconitem-container"
      :style="{ width: size.width, height: size.height }"
    >
      <a v-if="url" :href="url" target="_blank">
        <img
          :src="iconSource"
          :alt="altText"
          :style="{
            width: size.width,
            height: size.height,
            borderRadius: borderRadius,
          }"
        />
      </a>
      <img
        v-else
        :src="iconSource"
        :alt="altText"
        :style="{
          width: size.width,
          height: size.height,
          borderRadius: borderRadius,
        }"
      />
    </div>
  </template>
  
  <script>
  export default {
    props: {
      iconSource: String,
      url: String || null,
      altText: String,
      size: {
        width: String,
        height: String,
      },
      curve: String || null,
    },
    computed: {
      borderRadius() {
        return this.curve ? "99px" : "0px";
      },
    },
  };
  </script>
  
  <style scoped>
  .iconitem-container {
    width: auto;
    height: auto;
  }
  </style>