<template>
  <div class="iconlist-container">
    <ul class="social-ul">
      <li v-for="socialMedia in socialMediaList" :key="socialMedia.id">
        <IconItem
          :icon-source="socialMedia.iconUrl"
          :url="socialMedia.url"
          :alt-text="socialMedia.name"
          :size="iconSize"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import IconItem from "./IconItem.vue";
import xIcon from "@/assets/x.png";
import linkedinIcon from "@/assets/linkedin.png";
import githubIcon from "@/assets/github.png";
export default {
  components: {
    IconItem,
  },
  props: {
    iconList: Array,
  },
  data() {
    return {
      socialMediaList: [
        {
          id: 1,
          name: "x",
          url: "https://twitter.com/mikeyismky",
          iconUrl: xIcon,
        },
        {
          id: 2,
          name: "linkedin",
          url: "https://www.linkedin.com/in/mikeyliow/",
          iconUrl: linkedinIcon,
        },
        {
          id: 3,
          name: "github",
          url: "https://github.com/mikeyliow",
          iconUrl: githubIcon,
        },
      ],
    };
  },
  computed: {
    iconSize() {
      if (window.innerWidth > 600) {
        return { width: "24px", height: "24px" };
      } else {
        return { width: "16px", height: "16px" };
      }
    },
  },
};
</script>

<style scoped>
.social-ul {
  list-style: none;
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
}

.social-ul li {
  margin: 0 10px;
  padding: 0;
}

a > img {
  max-width: 32px;
  max-height: 32px;
}
@media (max-width: 600px) {
  a > img {
    max-width: 8px;
    max-height: 8px;
  }
}
</style>
