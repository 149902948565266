<template>
  <div class="box">
    <p>Want to know more? Ask MikeyAI here</p>
    <div class="input-bar">
      <input v-model="userInput" @keyup.enter="callLLMApi" placeholder="Enter your input" />
      <button @click="callLLMApi" :disabled="loading">Submit</button>
    </div>
    <div class="output-bar">
      <div v-if="loading">
        phrasing my response...
      </div>
      <p v-else v-html="formattedResponse"></p>
    </div>
  </div>
</template>

<script>
export default {
//   components: {
// Spinner
//   },
  data() {
    return {
      userInput: '',
      apiResponse: `
        Ask me anything about mikey here! Some common questions include
        \n- What are your most notable skills?
        \n- How does your work history look like?
        \n- What do you look for in a job?
        \n\nor it could be something more casual
        \n- What's your relationship status?
        \n- Who is your favourite sports team?
        \n- What is your workstation setup?
      `,
      loading: false
    };
  },
  computed: {
    formattedResponse() {
      return String(this.apiResponse.replace(/\n/g, '<br>'));
    }
  },
  methods: {
    async callLLMApi() {
      this.loading = true;
      const apiUrl = 'https://api.mymky.xyz/';
      try {
        const response = await fetch(apiUrl, {
          method: 'POST',
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            message: this.userInput 
          })
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.apiResponse = data;
        console.log(this.apiResponse);
      } catch (error) {
        console.error('Error calling LLM API:', error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50%;
  /* border-left: 1px solid #ccc;
  border-right: 1px solid #ccc; */
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.input-bar {
  flex: 1;
  display: flex;
  align-items: center;
}

.input-bar input {
  flex: 1;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

.input-bar button {
  padding: 5px 10px;
  border: none;
  background-color: #60606000;
  color: white;
  /* border-radius: 4px; */
  cursor: pointer;
}

.input-bar input,
.input-bar button {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.output-bar {
  flex: 9;
  overflow-y: auto;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  text-align: left;
  line-height: 1.3;
}

@media (max-width: 1200px) {
  .box {
    width: 60%;
  }
}
@media (max-width: 600px) {
  .box {
    width: 80%;
  }
}
</style>
