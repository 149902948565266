<template>
  <div class="container">
    <div class="main-container">
      <div class="main-container-icon">
        <IconMain
          :icon-source="imageUrl"
          :alt-text="'mikey as avatar'"
          :size="iconComputedSize"
        />
      </div>

      <div class="main-container-text-content">
        <h1>{{ mainHeader }}</h1>
        <h2>{{ subHeader }}</h2>
        <p>{{ text }}</p>
      </div>
    </div>
    <IconList/>
    <ChatBox></ChatBox>
    <div class="footer-email" @click="copyToClipboard">
      <p>{{ myEmail }}</p>
    </div>
  </div>
</template>

<script>
import IconMain from "@/components/IconMain.vue";
import IconList from "@/components/IconList.vue";
import iconImage from '@/assets/icon.png'
import ChatBox from "@/components/ChatBox.vue";
export default {
  components: {
    IconList,
    IconMain,
    ChatBox,
  },
  data() {
    return {
      imageUrl: iconImage,
      mainHeader: "Hey, I'm Mikey 🇲🇾",
      subHeader: "Day developer, night academic, always waffling",
      text: `SWE @ Grab, MCs @ Uni-Malaya. Always full of ideas, aspiring to build the next big thing.`,
      myEmail: "mikeyliowgianhao@gmail.com",
      socialMediaList: [
        {
          id: 1,
          name: "twitter",
          url: "https://twitter.com/mikeyismky",
          iconUrl:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/twitter.png",
        },
        {
          id: 2,
          name: "linkedin",
          url: "https://www.linkedin.com/in/mikeyliow/",
          iconUrl:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/linkedin.png",
        },
        {
          id: 3,
          name: "github",
          url: "https://github.com/mikeyliow",
          iconUrl:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/github.png",
        },
      ],
      programmingLanguageList: [
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/python.png",
          skillName: "python",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/aws.png",
          skillName: "amazon web services",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/golang.png",
          skillName: "go",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/js.png",
          skillName: "javascript",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/ts.png",
          skillName: "typescript",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/java.png",
          skillName: "java",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/sql.png",
          skillName: "sql",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/postgresql.png",
          skillName: "postgresql",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/django.png",
          skillName: "django",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/flask.png",
          skillName: "flask",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/vuejs.png",
          skillName: "vuejs",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/mysql.png",
          skillName: "mysql",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/linux.png",
          skillName: "linux",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/git.png",
          skillName: "git",
          skillColour: "#FFF",
        },
        {
          skillIconSource:
            "https://public-test-assets.s3.ap-southeast-1.amazonaws.com/skills/cicd.png",
          skillName: "ci/cd",
          skillColour: "#FFF",
        },
      ],
    };
  },
  methods: {
    copyToClipboard() {
      const textField = document.createElement("textarea");
      textField.innerText = this.myEmail;
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      alert("Email copied to clipboard!");
    },
  },
  computed: {
    iconComputedSize() {
      if (window.innerWidth > 600) {
        return {
          width: "280px",
          height: "280px",
        };
      } else {
        return {
          width: "180px",
          height: "180px",
        };
      }
    },

    iconListComputedMargin() {
      if (window.innerWidth > 600) {
        return { margin: "48px 0" };
      } else {
        return { margin: "8px 0" };
      }
    },
  },
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0 0 0 0;
  justify-content: center;
  align-items: center;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.2rem;
  text-align: justify;
}
p {
  text-align: start;
  font-size: 1.1rem;
  line-height: 1.5;
  text-align: justify;
}
.main-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0;
  margin: 0;
}
.main-container-icon {
  display: flex;
}
.main-container-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 500px;
}
.sub-container {
  display: flex;
  max-width: 650px;
}
.collab-btn {
  display: inline-block;
  font-size: 2rem;
  padding: 32px 84px;
  border-radius: 64px;
  font-family: inherit;
  transition: background-color 0.1s ease, transform 0.1s ease;
  background-color: #9d9d9d;
}

.collab-btn:hover {
  background-color: #414141;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}
.footer-email {
  bottom: 0;
  /* position: absolute; */
  margin: 16px;
}
.footer-email:hover {
  cursor: pointer;
}

.footer-email > p {
  font-size: 22px;
}
@media (max-width: 600px) {
  .container {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 32px 0 0 0;
    justify-content: center;
    align-items: center;
  }
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    margin: 0;
  }
  .main-container-icon {
    display: flex;
  }
  .main-container-text-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
  }

  /* .sub-container {
    display: flex;
    max-width: 1000px;
  } */
  .footer-email {
    margin: 4px;
  }
  .footer-email > p {
    font-size: 16px;
  }
  h1 {
    font-size: 1.2rem;
    text-align: center;
  }

  h2 {
    font-size: 1rem;
    text-align: center;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.3;
    text-align: center;
    padding: 8px;
  }
}
</style>